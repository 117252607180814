import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import CustomModal from 'components/organisms/Modal';
import Table, { DataTableTypes, HeaderTypes } from 'components/organisms/Table';

interface PopupTableProps {
  dataTable?: DataTableTypes[];
  headerTable?: HeaderTypes[];
  isOpen: boolean;
  handleClose?: () => void;
  note?: string;
}

const PopupTable: React.FC<PopupTableProps> = ({
  headerTable,
  dataTable,
  isOpen,
  handleClose,
  note,
}) => (
  <div className="t-popupTable">
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Table headerTable={headerTable} dataTable={dataTable} />
      {note && (
        <div className="t-popupTable_note">
          <Text type="span" modifiers={['eerieBlack', '14x20', '600']}>Ghi chú: </Text>
          <Text type="span" modifiers={['eerieBlack', '14x20']}>{note}</Text>
        </div>
      )}
      <div className="t-popupTable_button">
        <Button modifiers={['primary', 'md']} onClick={handleClose}>
          <Text modifiers={['white', '16x24', '600']}>
            Đóng
          </Text>
        </Button>
      </div>
    </CustomModal>
  </div>
);

export default PopupTable;
