import React from 'react';

import Image from 'components/atoms/Image';
import Carousel, { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import CustomModal from 'components/organisms/Modal';

interface PopupImageProps {
  open?: boolean;
  listImage: string[];
  handleClose?: () => void;
}

const PopupImage: React.FC<PopupImageProps> = ({
  open,
  listImage,
  handleClose,
}) => {
  const setting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow customArrow="white" />,
    nextArrow: <NextArrow customArrow="white" />,
    infinite: false,
    cssEase: 'ease-in-out',
  };

  return (
    <div className="popupImage">
      <CustomModal variant="image" isOpen={open || false} handleClose={handleClose} showIconClose>
        <div className="popupImage_carousel">
          <Carousel settings={setting}>
            {
              listImage?.map((item, index) => (
                <div key={`carousel-image-${index.toString()}`} className="popupImage_carousel-item">
                  <Image imgSrc={item} alt="carousel-image" size="contain" ratio="375x498" />
                </div>
              ))
            }
          </Carousel>
        </div>
      </CustomModal>
    </div>
  );
};

export default PopupImage;
